
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 2px solid black;
  width: 413px;

  .room{
    border: 1.5px solid blue;
    background: lightgoldenrodyellow;
    margin: 15px;
    padding: 2rem;
  }

  .rent {
    margin-top: 1rem;
    width: 100%;
    padding: 0;
    label {
      margin: 1rem;
    }
    max-width: 413px;
  }

  fieldset {
    .MuiFormControl-root {
      margin-bottom: 20px;
    }
    label {
      text-align: left;
      font-size: 24px;
      margin: 2px;
    }

    #closeButton {
      position: relative;
      top: -20px;
      right: -20px;
    }

    input[type="number"], input[type="text"] {
      font-size:24px;
      float: right;
    }

    button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      outline: inherit;
      float: right;  
      width: 1rem;
    }

    #nameLabel {
      margin-top: 17px;
    }

    max-width: 400px;
    border: none;
    display: flex;
    flex-direction: column;
  }

  input[type="submit"] {
    font-size:24px;
    box-sizing: border-box;
    margin: 10px;
    border-radius: 5px;
    font-family: 'DM Sans';
    border: 1.5px solid black;
  }

  button {
    border-radius: 5px;
    font-family: 'DM Sans';
    border: 1.5px solid black;
    margin-right: 3px;
  }

  p {
    margin: 0;
    width: 100%;
    font-size: 20px;
    color: blue;
    max-width: 400px;
    margin-left: 1rem;
    text-align: left;
    margin-top: 2rem;
  }
}

.results {
  display: flex;
    flex-direction: column;
    align-items: center;
    div {
  display: block;
  text-align: left;
}
}